import React from 'react';
import { ReactComponent as SignatureIcon } from '../../assets/img/new_icons/signature-icon.svg';
import { ReactComponent as InitialsIcon } from '../../assets/img/new_icons/initials-icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/new_icons/calendar-icon.svg';
import { ReactComponent as TextIcon } from '../../assets/img/new_icons/text-icon.svg';
import { ReactComponent as CheckboxIcon } from '../../assets/img/new_icons/checkbox--checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '../../assets/img/new_icons/checkbox--unchecked.svg';
import { ReactComponent as RadioIcon } from '../../assets/img/new_icons/radio.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../assets/img/new_icons/radio--unchecked.svg';
import { ReactComponent as AttachmentIcon } from '../../assets/img/new_icons/attachment.svg';
import { ReactComponent as UserIcon } from '../../assets/img/new_icons/user-icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/img/new_icons/email-icon.svg';
import { ReactComponent as CompanyIcon } from '../../assets/img/new_icons/build-icon.svg';
import { ReactComponent as CaseIcon } from '../../assets/img/new_icons/case-icon.svg';

export const SIGNATURE = 'signature';
export const INITIALS = 'initials';
export const DATE = 'date';
export const AUTO_DATE = 'autodate';
export const TEXT = 'text';
export const CHECKBOX = 'checkbox';
export const RADIO_GROUP = 'radioGroup';
export const RADIO_BUTTON = 'radioGroupOption';
export const ATTACHMENT = 'attachment';
export const FULL_NAME = 'fullName';
export const EMAIL = 'email';
export const COMPANY_NAME = 'company';
export const TITLE = 'title';
// Category
export const SIGN_FIELD = 'SIGN_FIELD';
export const PREFILL = 'PREFILL';
export const POINTER = 'POINTER'; // Default cursor tool

// Prefill Tools
export const CHECKMARK = 'checkmark';
export const REDACT = 'redact';
export const HIGHLIGHT = 'highlight';
export const ERASE = 'erase';

export const fieldsTypesCoordinates = {
  [SIGNATURE]: {
    label: 'Signature',
    labelMobile: 'Sign',
    maxWidth: 250,
    maxHeight: 70,
    minHeight: 10,
    minWidth: 30,
    width: 80,
    height: 20,
  },
  [INITIALS]: {
    label: 'Initials',
    labelMobile: 'Initials',
    maxWidth: 250,
    maxHeight: 70,
    minHeight: 10,
    minWidth: 30,
    width: 80,
    height: 20,
  },
  [TEXT]: {
    label: 'Text',
    labelMobile: 'Text',
    maxWidth: 400,
    maxHeight: 60,
    minHeight: 5,
    minWidth: 10,
    width: 60,
    height: 15,
  },
  [CHECKBOX]: {
    label: 'Checkbox',
    labelMobile: 'Check',
    maxWidth: 50,
    maxHeight: 50,
    minHeight: 5,
    minWidth: 5,
    width: 10,
    height: 10,
  },
  [RADIO_BUTTON]: {
    label: 'RadioButton',
    labelMobile: 'Radio',
    maxWidth: 50,
    maxHeight: 50,
    minHeight: 5,
    minWidth: 5,
    width: 10,
    height: 10,
  },
  [DATE]: {
    label: 'Date',
    labelMobile: 'Date',
    maxWidth: 250,
    maxHeight: 70,
    minHeight: 5,
    minWidth: 20,
    width: 80,
    height: 20,
  },
  [AUTO_DATE]: {
    label: 'Date',
    labelMobile: 'Date',
    maxWidth: 240,
    maxHeight: 50,
    minHeight: 5,
    minWidth: 20,
    width: 60,
    height: 15,
  },
  [ATTACHMENT]: {
    label: 'Attach',
    maxWidth: 100,
    maxHeight: 100,
    minHeight: 20,
    minWidth: 20,
    width: 40,
    height: 40,
  },
  [FULL_NAME]: {
    label: 'Full Name',
    maxWidth: 400,
    maxHeight: 60,
    minHeight: 5,
    minWidth: 10,
    width: 60,
    height: 15,
  },
  [EMAIL]: {
    label: 'Email',
    maxWidth: 400,
    maxHeight: 60,
    minHeight: 5,
    minWidth: 10,
    width: 60,
    height: 15,
  },
  [COMPANY_NAME]: {
    label: 'Company',
    maxWidth: 400,
    maxHeight: 60,
    minHeight: 5,
    minWidth: 10,
    width: 60,
    height: 15,
  },
  [TITLE]: {
    label: 'Title',
    maxWidth: 400,
    maxHeight: 60,
    minHeight: 5,
    minWidth: 10,
    width: 60,
    height: 15,
  },
};

export const PrefillToolsCoordinates = {
  [TEXT]: {
    label: '',
    labelMobile: '',
    maxWidth: -1,
    maxHeight: -1,
    minHeight: 5,
    minWidth: 5,
    width: 10,
    height: 18,
  },
  [CHECKMARK]: {
    label: 'Attach',
    minHeight: 5,
    minWidth: 5,
    width: 40,
    height: 40,
  },
};

export const FIELDS_ICONS = {
  [SIGNATURE]: <SignatureIcon />,
  [INITIALS]: <InitialsIcon />,
  [DATE]: <CalendarIcon />,
  FIELD_DATE: <CalendarIcon />,
  [AUTO_DATE]: <CalendarIcon />,
  [TEXT]: <TextIcon />,
  FIELD_TEXT: <TextIcon />,
  [CHECKBOX]: <CheckboxIcon />,
  [`${CHECKBOX}--unchecked`]: <CheckboxUncheckedIcon />,
  [RADIO_BUTTON]: <RadioIcon />,
  [`${RADIO_BUTTON}--unchecked`]: <RadioUncheckedIcon />,
  [ATTACHMENT]: <AttachmentIcon />,
  [FULL_NAME]: <UserIcon />,
  [EMAIL]: <EmailIcon />,
  [COMPANY_NAME]: <CompanyIcon />,
  [TITLE]: <CaseIcon />,
};

export const FIELDS_ICONS_ELEMENT = {
  [SIGNATURE]: props => <SignatureIcon {...props} />,
  [INITIALS]: props => <InitialsIcon {...props} />,
  [DATE]: props => <CalendarIcon {...props} />,
  FIELD_DATE: props => <CalendarIcon {...props} />,
  [AUTO_DATE]: props => <CalendarIcon {...props} />,
  [TEXT]: props => <TextIcon {...props} />,
  FIELD_TEXT: props => <TextIcon {...props} />,
  [CHECKBOX]: props => <CheckboxIcon {...props} />,
  [`${CHECKBOX}--unchecked`]: props => <CheckboxUncheckedIcon {...props} />,
  [RADIO_BUTTON]: props => <RadioIcon {...props} />,
  [`${RADIO_BUTTON}--unchecked`]: props => <RadioUncheckedIcon {...props} />,
  [ATTACHMENT]: props => <AttachmentIcon {...props} />,
  [FULL_NAME]: props => <UserIcon {...props} />,
  [EMAIL]: props => <EmailIcon {...props} />,
  [COMPANY_NAME]: props => <CompanyIcon {...props} />,
  [TITLE]: props => <CaseIcon {...props} />,
};

export const FIELDS_DETAIL_CONFIG = {
  [SIGNATURE]: {
    tooltip: true,
    placeholder: false,
  },
};

export const FIELDS_MENU = [
  {
    type: 'field',
    item: SIGNATURE,
    text: 'Signature',
    mobile: true,
  },
  {
    type: 'field',
    item: INITIALS,
    text: 'Initials',
    mobile: true,
  },
  {
    type: 'field',
    item: DATE,
    text: 'Date',
    mobile: true,
  },
  {
    type: 'field',
    item: TEXT,
    text: 'Text',
    mobile: true,
  },
  {
    type: 'field',
    item: CHECKBOX,
    text: 'Checkbox',
    mobile: true,
  },
  {
    type: 'field',
    item: RADIO_BUTTON,
    text: 'Radio Button',
    mobile: false,
  },
  {
    type: 'field',
    item: ATTACHMENT,
    text: 'Attachment',
    mobile: false,
  },
  {
    type: 'divider',
    mobile: false,
  },
  {
    type: 'field',
    item: FULL_NAME,
    text: 'Full Name',
    mobile: false,
  },
  {
    type: 'field',
    item: EMAIL,
    text: 'Email',
    mobile: false,
  },
  {
    type: 'field',
    item: COMPANY_NAME,
    text: 'Company',
    mobile: false,
  },
  {
    type: 'field',
    item: TITLE,
    text: 'Title',
    mobile: false,
  },
];

export const DEFAULT_ITEM = {
  category: '',
  type: '',
  isRequired: true,
  tooltip: '',
  placeholder: '',
  fieldName: '',
  isConditional: false,
  role: '',
  isFocused: false,
  isEditing: false,
  isDragging: false,
};

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const DATE_FORMAT_OPTIONS = [
  {
    label: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  {
    label: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    label: 'YYYY/MM/DD',
    value: 'YYYY/MM/DD',
  },
];

export const DEFAULT_TEMPLATE_ITEM = {
  id: '',
  fileId: '',
  signers: '',
  role: '',
  email: '',
  name: '',
  type: '',
  color: '',
  order: '',
  completed: '',
  reRequestSigners: '',
  expiresAt: '',
  createdAt: '',
  status: '',
  sender: '',
  fullName: '',
  fileUrl: '',
  isCompletedBySigner: '',
  file: '',
  ordered: '',
  signerMainSettings: '',
  metadata: '',
};
