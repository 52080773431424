import {
  LOGIN_USER,
  SET_LOADING,
  SET_IS_2FA_MODAL_OPEN,
  LOGIN_USER_2FA,
  LOGIN_USER_EXTERNALLY,
  REFRESH_USER_DATA,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_STORAGE_REMAINING,
  STORAGE_REMAINING_SUCCESS,
  SET_USER_FULL_NAME,
  SET_USER_EMAIL,
  SET_USER_AVATAR,
  SET_USER_ACCOUNT_TYPE,
  SET_USER_ADDRESS,
  SET_USER_PHONE,
  SET_USER_REGIONAL_SETTINGS,
  SET_USER_SIGNING_PROCESS,
  SET_USER_NOTIFICATION,
  SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS,
  SET_USER_CONTACTS_AUTO_FILL_SETTINGS,
  SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS,
  SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS,
  SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS,
  SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS,
  SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS,
  SET_USER_TWO_FACTOR_AUTH,
  SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR,
  DELETE_USER_TWO_FACTOR_AUTH_APP,
  SET_USER_TWO_FACTOR_AUTH_EMAIL,
  DELETE_USER_TWO_FACTOR_AUTH_EMAIL,
  SET_SHARED_LINK,
  CLEAR_SHARED_LINK,
  SET_CONFIRM_EMAIL_SEND,
  SET_IS_EMAIL_VERIFIED,
  SET_USER_COMPANY_NAME,
  SET_USER_JOB_TITLE,
  SET_SHOW_LOGIN_MODAL_OPEN,
  SET_USER_TEMPLATE_TIP_SETTINGS,
  SET_USER_NOTIFICATION_EMAIL_ON_INVOICE_SETTINGS,
} from '../actions';

export const loginUser = (user, history, setShowLoader) => ({
  type: LOGIN_USER,
  payload: { user, history, setShowLoader },
});

export const setGlobalLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const triggerAfterSwitchAccount = () => ({
  type: REFRESH_USER_DATA,
});

export const loginUser2FA = (loginUser, history) => ({
  type: LOGIN_USER_2FA,
  payload: { loginUser, history },
});

export const loginUserExternally = (loginUser, history) => ({
  type: LOGIN_USER_EXTERNALLY,
  payload: { loginUser, history },
});

export const setIs2FAModalOpen = isOpen => ({
  type: SET_IS_2FA_MODAL_OPEN,
  payload: isOpen,
});

export const setShowLoginModalOpen = isOpen => ({
  type: SET_SHOW_LOGIN_MODAL_OPEN,
  payload: isOpen,
});

export const setUserFullName = fullName => ({
  type: SET_USER_FULL_NAME,
  payload: fullName,
});

export const setUserCompanyName = companyName => ({
  type: SET_USER_COMPANY_NAME,
  payload: companyName,
});

export const setUserJobTitle = jobTitle => ({
  type: SET_USER_JOB_TITLE,
  payload: jobTitle,
});

export const setUserEmail = email => ({
  type: SET_USER_EMAIL,
  payload: email,
});

export const setUserAvatar = avatarUrl => ({
  type: SET_USER_AVATAR,
  payload: avatarUrl,
});

export const setUserAccountType = isBusiness => ({
  type: SET_USER_ACCOUNT_TYPE,
  payload: isBusiness,
});

export const setUserAddress = address => ({
  type: SET_USER_ADDRESS,
  payload: address,
});

export const setUserPhone = phone => ({
  type: SET_USER_PHONE,
  payload: phone,
});

export const setUserRegionalSettings = regionalSettings => ({
  type: SET_USER_REGIONAL_SETTINGS,
  payload: regionalSettings,
});

export const setUserNotification = notificationState => ({
  type: SET_USER_NOTIFICATION,
  payload: notificationState,
});

export const setUserTwoFactor = isEnable => ({
  type: SET_USER_TWO_FACTOR_AUTH,
  payload: isEnable,
});

export const setUserTwoFactorAuthenticator = (enabledAt, device) => ({
  type: SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR,
  payload: { enabledAt, device },
});

export const deleteUserTwoFactorAuthenticator = () => ({
  type: DELETE_USER_TWO_FACTOR_AUTH_APP,
});

export const setUserTwoFactorEmail = enabledAt => ({
  type: SET_USER_TWO_FACTOR_AUTH_EMAIL,
  payload: { enabledAt },
});

export const deleteUserTwoFactorEmail = () => ({
  type: DELETE_USER_TWO_FACTOR_AUTH_EMAIL,
});

export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = error => ({
  type: LOGIN_USER_ERROR,
  payload: error,
});

export const getCurrentUser = (history, path) => ({
  type: GET_CURRENT_USER,
  payload: { history, path },
});

export const getCurrentUserSuccess = user => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: { user },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});

export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const getStorageRemaining = () => ({
  type: GET_STORAGE_REMAINING,
});

export const storageRemainingSuccess = storage => ({
  type: STORAGE_REMAINING_SUCCESS,
  payload: storage,
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const setSharedLink = link => ({
  type: SET_SHARED_LINK,
  payload: link,
});

export const clearSharedLink = () => ({
  type: CLEAR_SHARED_LINK,
});

export const setConfirmEmailSend = send => ({
  type: SET_CONFIRM_EMAIL_SEND,
  payload: send,
});

export const setIsSetEmailVerified = verified => ({
  type: SET_IS_EMAIL_VERIFIED,
  payload: verified,
});

export const setUserContactsSaveRecepientsSettings = contactsSaveRecepients => ({
  type: SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS,
  payload: contactsSaveRecepients,
});

export const setUserContactsAutoFillSettings = contactsAutoFill => ({
  type: SET_USER_CONTACTS_AUTO_FILL_SETTINGS,
  payload: contactsAutoFill,
});

export const setUserNotificationRecipientViewsSignRequestSettings = recipientViewsSignRequest => ({
  type: SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS,
  payload: recipientViewsSignRequest,
});

export const setUserNotificationRecipientSignADocumentSettings = recipientSignADocument => ({
  type: SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS,
  payload: recipientSignADocument,
});

export const setUserNotificationDocumentHasBeenSignedAllPartiesSettings = documentHasBeenSignedAllParties => ({
  type: SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS,
  payload: documentHasBeenSignedAllParties,
});

export const setUserNotificationBrowserPushNotificationsSettings = browserPush => ({
  type: SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS,
  payload: browserPush,
});

export const setUserNotificationInAppPushNotificationsSettings = inAppPush => ({
  type: SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS,
  payload: inAppPush,
});

export const setUserNotificationEmailOnInvoiceSettings = emailOnInvoice => ({
  type: SET_USER_NOTIFICATION_EMAIL_ON_INVOICE_SETTINGS,
  payload: emailOnInvoice,
});

export const setUserSigningProcess = signingProcess => ({
  type: SET_USER_SIGNING_PROCESS,
  payload: signingProcess,
});

export const setUserTemplateTipSettings = skipTipModal => ({
  type: SET_USER_TEMPLATE_TIP_SETTINGS,
  payload: skipTipModal,
});
