import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageDetectionMethod, isMobileBrowser } from '../../utils';

import { ReactComponent as InfoIcon } from '../../assets/img/info.svg';

import './style.scss';
import { getFreeSignatureStatusV2 } from '../../api/signature';

const SignRemaining = ({ user }) => {
  const [showSigningRemaining, setShowSigningRemaining] = useState(false);
  const [limit, setLimit] = useState();
  const [used, setUsed] = useState();

  const history = useHistory();

  useEffect(() => {
    getFreeSignatureStatusHandler();
  }, []);

  useEffect(() => {
    getFreeSignatureStatusHandler();
  }, [PageDetectionMethod.Documents()]);

  const getFreeSignatureStatusHandler = () => {
    getFreeSignatureStatusV2().then(res => {
      if (res) {
        if (!!res?.limit) {
          setLimit(res?.limit);
          setUsed(res?.used);
          setShowSigningRemaining(true);
        } else {
          setLimit();
          setUsed();
          setShowSigningRemaining(false);
        }
      } else {
        setLimit();
        setUsed();
        setShowSigningRemaining(false);
      }
    });
  };

  return (
    showSigningRemaining && (
      <div
        className={`sign-remaining ${isMobileBrowser() ? 'mobile' : ''}`}
        style={{
          width: isMobileBrowser() ? '100%' : '230px',
        }}
      >
        {user?.subscription?.freeTrial?.isActive ? (
          <div className="sign-remaining__description sign-remaining__description--over">
            7-Day Trial
            <label>
              <InfoIcon />
              <span className="tooltip">
                The 7-day trial provides 3 free sign <br />
                requests per month, and <br />
                unlimited self-signing. <br />
                <br />
                Upgrade to a Premium plan <br />
                to unlock unlimited sign requests.
              </span>
            </label>
          </div>
        ) : (
          <div className="sign-remaining__description sign-remaining__description--over">
            Free Plan
            <label>
              <InfoIcon />
              <span className="tooltip">
                The free plan provides 3 free sign <br />
                requests per month, and <br />
                unlimited self-signing. <br />
                <br />
                Upgrade to a Premium plan <br />
                to unlock unlimited sign requests.
              </span>
            </label>
          </div>
        )}
        <div className="sign-remaining__range">
          <p
            style={{
              width: `${(used / limit) * 100}%`,
              background: (used / limit) * 100 >= 100 ? '#FF0000' : '#C7C7CC',
            }}
          />
        </div>
        <div className="sign-remaining__description sign-remaining__description--under">
          {used > limit ? limit : used} / {limit} requests sent
          <span onClick={() => history.push('/settings/billing/plans')}>
            Upgrade
          </span>
        </div>
      </div>
    )
  );
};

export default SignRemaining;
